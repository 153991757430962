class Carousel {
  constructor(prevArw, nextArw) {
    this.options = {
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: false,
      speed: 800,
      infinite: true,
      prevArrow: '<span class="slick-prev prev-slick slick-nav home-news-prev"><i class="fas fa-angle-left"></i></span>',
      nextArrow: '<span class="slick-next next-slick slick-nav home-news-next"><i class="fas fa-angle-right"></i></span>',
      autoplay: true,
      centerPadding: '0px',
      responsive: [{
          breakpoint: 992,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    }
  }

  init() {
    $('.group_carousel_init').slick(this.options);
  }

  refresh() {
    $('.group_carousel_init').slick('refresh');
  }
}

export default Carousel;