class HomeCarousel {
  constructor(prevArw, nextArw) {
    this.options = {
      slidesToShow: 4,
      prevArrow: '<span class="slick-prev prev-slick slick-nav home-news-prev"><i class="fas fa-angle-left"></i></span>',
      nextArrow: '<span class="slick-next next-slick slick-nav home-news-next"><i class="fas fa-angle-right"></i></span>',
      dots: false,
      speed: 800,
      infinite: false,
      autoplay: true,
      centerPadding: '0px',
      responsive: [{
          breakpoint: 992,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    }
  }

  init() {
    $('.home-carousel-wrapper').slick(this.options);
  }
  refresh() {
    $('.home-carousel-wrapper').slick('refresh');
  }
}

export default HomeCarousel;